import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Футбольний клуб Sport Kids
			</title>
			<meta name={"description"} content={"У Sport Kids ми дихаємо футболом. Наш клуб присвячений розвитку талантів, відточуванню навичок та вихованню любові до прекрасної гри."} />
			<meta property={"og:title"} content={"Головна | Футбольний клуб Sport Kids"} />
			<meta property={"og:description"} content={"У Sport Kids ми дихаємо футболом. Наш клуб присвячений розвитку талантів, відточуванню навичок та вихованню любові до прекрасної гри."} />
			<meta property={"og:image"} content={"https://starepumpas.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starepumpas.com/img/4050240.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starepumpas.com/img/4050240.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starepumpas.com/img/4050240.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Футбольний клуб Sport Kids
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						У Sport Kids ми дихаємо футболом. Наш клуб присвячений розвитку талантів, відточуванню навичок та вихованню любові до прекрасної гри. Незалежно від того, чи є ви молодим талантом, чи досвідченим гравцем, який прагне вдосконалити свої навички, наш клуб забезпечує ідеальне середовище для досягнення ваших футбольних прагнень.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							href="/contact"
							type="link"
							text-decoration-line="initial"
							hover-background="rgba(63, 36, 216, 0)"
						>
							Контакти
						</Button>
					</Box>
				</Box>
				<Image
					src="https://starepumpas.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="p" font="--base" color="#3f24d8" margin="0px 0px 8px 0px">
					Про Нас
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Чому варто обрати нас?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
				>
					- Професійні тренери: Наша команда досвідчених тренерів володіє знаннями в різних аспектах гри, забезпечуючи всебічну підготовку.
					<br />
					<br />
					- Найсучасніші умови: Завдяки високоякісним полям, фітнес-залам та тренувальному обладнанню, наші об'єкти задовольняють усі ваші футбольні потреби.
					<br />
					<br />
					- Індивідуальні тренувальні програми: Ми розуміємо, що кожен гравець унікальний. Наші тренувальні програми розроблені таким чином, щоб враховувати індивідуальні сильні сторони та сфери для вдосконалення.
					<br />
					<br />
					- Змагальні можливості: Регулярні матчі та турніри дають нашим членам можливість випробувати реальні ігрові сценарії та змагальну гру.
					<br />
					<br />
					- Всебічний розвиток: Ми зосереджуємося не лише на фізичних навичках, але й на ментальних, тактичних та стратегічних аспектах футболу.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://starepumpas.com/img/2.jpg" display="block" border-radius="32px" />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="33.333%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 140% 0px"
					height="auto"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						src="https://starepumpas.com/img/3.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				width="33.333%"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
			>
				<Box
					padding="0px 0px 140% 0px"
					width="100%"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					height="auto"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						width="100%"
						bottom="0px"
						min-height="100%"
						display="block"
						top="auto"
						left={0}
						right={0}
						src="https://starepumpas.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="33.333%"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Sport Kids - де футбольні мрії стають реальністю
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});